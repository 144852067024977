var FavoriteAgenda = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var
        months,
        days,
        $templateAgendaItem;

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init() {
        $templateAgendaItem = $('template#tplFavoriteAgendaItem').remove().contents();
        $(document)
            .on('click', '#favorite-agenda #favorite-agendaList .item', evtClick)
            .on('click', '#favorite-agenda #favorite-agendaList .item .favorite_agenda', favoriteAgenda);
        Build();
        delete Agenda.Init;
    }


    /* ----- Build ---------------------------------------------------------------------------------------------------- */
    function Build() {
        sortAgenda();
        renderList();
    }

    function BuildFromSource() {
        $.post('api/', {
            do: 'getProfile'
        }, function (data) {
            AppData.profile = data.profile;
        });
    }

    /* ----- Sort List ------------------------------------------------------------------------------------------------ */
    function sortAgenda() {
        (AppData.agenda || []).sort(function (a, b) {
            if (a.date == b.date) {
                return (a.timeStart < b.timeStart) ? -1 : (a.timeStart > b.timeStart) ? 1 : 0;
            } else {
                return (a.date < b.date) ? -1 : 1;
            }
        });
    }

    /* ----- Render Agenda List --------------------------------------------------------------------------------------- */
    function renderList() {
        /* Check AppData */
        if (!'agenda' in AppData || !(AppData.agenda || []).length) {
            return false;
        }
        var
            $self = $('#favorite-agenda #favorite-agendaList'),
            state = localState.agenda,
            $fragmentAgenda = $(document.createDocumentFragment());
        /* Build List */

        var favorite_agendas = AppData.agenda.filter(function (item) {
            var in_favs = AppData.profile.favorite_agenda.find(function (fa) {
                return fa.agenda == item.id;
            });

            return in_favs ? true : false;
        });

        $.each(favorite_agendas, function (i, el) {
            var $clone = $templateAgendaItem.clone();
            renderRow($clone, el);
            $clone.appendTo($fragmentAgenda);
            $clone = null;
        });
        /* Append List  */
        $self.html($fragmentAgenda);
        $self = state = $fragmentAgenda = null;
    }

    /* ----- Render Agenda Row ---------------------------------------------------------------------------------------- */
    function renderRow($elem, data) {
        var timeformat = AppData.translations.filter(function(e) {
            return e.translation_id === localState.lang;
        })[0];

        $elem.toggleClass('now', data.status === 'now');
        $elem.toggleClass('ended', data.status === 'ended');
        $elem.toggleClass('breaking', !data.rooms && !data.details);
        $elem.toggleClass('link', !!data.rooms || !!data.details);
        $elem.attr('data-id', data.id);
        $elem.find('.data-timeStart').text(makeTimeFormat(data.timeStart, timeformat.time_format));
        $elem.find('.data-timeEnd').text(makeTimeFormat(data.timeEnd, timeformat.time_format));
        /*var room = AppData.rooms.find( function ( el ) {
          return el.id === data.rooms
        } );*/

        /*var day = AppData.days.find(function (el) {
            return el.id == data.day;
        });

        if (day) {
            $elem.find(".data-day").html(day.name[localState.lang]);
        }*/

        var room = AppData.channels.find(function(e) {
            return e.channel === data.rooms;
        }) || false; // bungi

        let eldays = AppData.days.filter(function (d) {
            return data.day.indexOf(d.id) > -1;
        });

        let daytext = [];
        for (let i = 0; i < eldays.length; i++) {
            daytext.push(eldays[i].name[localState.lang]);
        }

        $elem.find('.data-rooms')
            .attr("data-room-id", data.rooms).text((daytext ? daytext.join(", ") + " | " : "") + (room ? room.name[localState.lang] : ""))
            .parent().toggleClass('hidden', !room);

        var atitle;
        if (typeof data.title == "string") {
            atitle = data.title;
        } else {
            atitle = data.title[localState.lang];
        }

        $elem.find('.data-title').text(atitle);
        $elem.find('[data-lang=now]').text(Language.getItem('now'));
        $elem.find('[data-lang=expired]').text(Language.getItem('expired'));

        $elem.find('.data-title').toggleClass('title-short',atitle.length < 35);
        $elem.find('.data-title').toggleClass('title-long', atitle.length >= 70);
        $elem.find('.data-title').toggleClass('title-extra', atitle.length >= 120);
    }

    /* ----- Event: Click on Item ------------------------------------------------------------------------------------- */
    function evtClick() {
        Agenda.showDetails(String($(this).data('id')));
    }

    function favoriteAgenda(e) {
        e.stopPropagation();
        var that = $(this);
        var item = that.closest($(".item")).attr("data-id");

        Modal.confirm(Language.getItem("favorite-agenda-delete"), function () {
            $.ajax({
                method: 'post',
                url: 'api/',
                data: {
                    do: 'saveFavoriteAgenda',
                    id: item
                },
                success: function(resp) {
                    that.closest($(".item")).remove();
                    AppData.profile = resp.profile;
                }
            });
        });
    }

    function showFavorite() {
        if (AppData.modulsettings.showfavorite) {
            $("#agenda-details .favorite_agenda").show();
        } else {
            $("#agenda-details .favorite_agenda").hide();
        }
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        Build: Build,
        BuildFromSource: BuildFromSource,
        evtClickDetails: evtClick,
        showFavorite: showFavorite,
        renderList: renderList
    };
})();
